<template>
    <main class>
      <v-app class>
        <div>
          <v-alert
            v-model="alert"
            border="left"
            dark
            dismissible
            class="alert"
            :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
          >
            {{alertMessage}}
          </v-alert>
        </div>
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 text-center">
                <h1>Personnel Move</h1>
              </div>
              <div class="w-100">
                <v-expansion-panels multiple class="mb-5">
                  <v-col cols="12" sm="12">
                    <v-text-field
                    dense
                    readonly
                    outlined
                    required
                    type="text"
                    label="Personnel"
                    v-model="personnelName"
                    :append-icon="'mdi-magnify'"
                    @click:append="personnelSearch = true"
                    @click.prevent="personnelSearch = true" />
                  </v-col>
                  <v-expansion-panel>
                    <v-expansion-panel-header><b>Current Personnel Details</b></v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-form ref="employmentForm">
                        <v-row dense>
                          <v-col cols="12" sm="6">
                            <v-select disabled v-model="formData.oldWorkArea" :items="workAreas" item-value="id" item-text="name" label="Work Area" return-object outlined dense />
                          </v-col>

                          <v-col cols="12" sm="6">
                            <v-select disabled v-model="formData.oldLocation" :items="locations" item-value="id" item-text="name" label="Location" return-object outlined dense />
                          </v-col>

                          <v-col cols="12" sm="6">
                            <v-autocomplete :items="chargeCode"
                              disabled
                              v-model="formData.oldChargeCode"
                              outlined
                              dense
                              item-text="costCenter"
                              label="Charge Code"
                              return-object
                              item-value="costCenter"></v-autocomplete>

                          </v-col>

                          <v-col cols="12" sm="6">
                            <v-select disabled @change="unionMemberChange" v-model="formData.oldUnionMembership" :items="unionMembers" item-value="id" item-text="name" label="Union Membership" return-object outlined dense />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <!-- <v-select v-model="formData.JobTitle" :items="jobTitles" item-value="id" item-text="name" label="Job Title" return-object outlined dense /> -->
                          <v-autocomplete :items="jobTitles"
                            v-model="formData.oldJobTitle"
                            disabled
                            outlined
                            dense
                            item-text="name"
                            label="Job Titles"
                            return-object
                            item-value="id"></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField v-model="formData.oldJobTitle.salaryAmount"
                              :disable="true"
                              :label="'Salary Amount'" />
                          </v-col>

                          <v-col cols="12" sm="6">
                            <v-select disabled v-model="formData.oldDepartment" :items="departments" item-value="id" item-text="name" label="Department" return-object outlined dense />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-select disabled v-model="formData.oldRotation" :items="rotations" item-value="id" item-text="name" label="Rotation" return-object outlined dense />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <!-- <v-select @change="unionMemberChange" v-model="formData.OrgIdentification" :items="organisationIds" item-value="id" item-text="orgId" label="Org ID" return-object outlined dense/> -->
                            <v-autocomplete :items="orgId"
                              v-model="formData.oldOrgIdentification"
                              disabled
                              outlined
                              dense
                              item-text="divisionName"
                              label="Division"
                              return-object
                              item-value="name"></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              disabled
                              dense
                              readonly
                              outlined
                              required
                              type="text"
                              label="Supervisor"
                              v-model="oldSupervisorName"
                              :append-icon="'mdi-magnify'"
                              @click:append="supervisorSearch = true"
                              @click.prevent="supervisorSearch = true" />

                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              disabled
                              readonly
                              outlined
                              required
                              type="text"
                              label="Alternate Supervisor"
                              v-model="oldAltSupervisorName"
                              :append-icon="'mdi-magnify'"
                              @click:append="altSupervisorSearch = true"
                              @click.prevent="altSupervisorSearch = true" />

                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              disabled
                              readonly
                              outlined
                              required
                              type="text"
                              label="Superintendent"
                              v-model="oldSuperintendentName"
                              :append-icon="'mdi-magnify'"
                              @click:append="superintendentSearch = true"
                              @click.prevent="superintendentSearch = true" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              disabled
                              readonly
                              outlined
                              required
                              type="text"
                              label="Alternate Superintendent"
                              v-model="oldAltSuperintendentName"
                              :append-icon="'mdi-magnify'"
                              @click:append="altSuperintendentSearch = true"
                              @click.prevent="altSuperintendentSearch = true" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              disabled
                              dense
                              readonly
                              outlined
                              required
                              label="GM"
                              type="text"
                              v-model="oldGMname"
                              :append-icon="'mdi-magnify'"
                              @click:append="gmSearch = true"
                              @click.prevent="gmSearch = true"
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- Move Employee data form section   -->
                  <v-expansion-panel>
                    <v-expansion-panel-header><b>Proposed Personnel Move Details</b></v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-form ref="employmentForm" v-model="valid">
                        <v-row dense>
                          <v-col cols="12" sm="6">
                            <v-select required v-model="formData.WorkArea" :items="workAreas" item-value="id" item-text="name" label="Work Area" return-object outlined dense />
                          </v-col>

                          <v-col cols="12" sm="6">
                            <v-select required v-model="formData.Location" :items="locations" item-value="id" item-text="name" label="Location" return-object outlined dense />
                          </v-col>

                          <v-col cols="12" sm="6">
                            <v-autocomplete :items="chargeCode"
                              v-model="formData.chargeCode"
                              outlined
                              required
                              dense
                              item-text="costCenter"
                              label="Charge Code"
                              return-object
                              item-value="costCenter"></v-autocomplete>

                          </v-col>

                          <v-col cols="12" sm="6">
                            <v-select required @change="unionMemberChange" v-model="formData.UnionMembership" :items="unionMembers" item-value="id" item-text="name" label="Union Membership" return-object outlined dense />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <!-- <v-select v-model="formData.JobTitle" :items="jobTitles" item-value="id" item-text="name" label="Job Title" return-object outlined dense /> -->
                            <v-autocomplete :items="jobTitles"
                              v-model="formData.JobTitle"
                              outlined
                              required
                              dense
                              item-text="name"
                              label="Job Titles"
                              return-object
                              item-value="id"></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField v-model="formData.JobTitle.salaryAmount"
                              :disable="true"
                              :label="'Salary Amount'" />
                          </v-col>

                          <v-col cols="12" sm="6">
                            <v-select required @change="departmentChange" v-model="formData.Department" :items="departments" item-value="id" item-text="name" label="Department" return-object outlined dense />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-select required v-model="formData.Rotation" :items="rotations" item-value="id" item-text="name" label="Rotation" return-object outlined dense />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <!-- <v-select @change="unionMemberChange" v-model="formData.OrgIdentification" :items="organisationIds" item-value="id" item-text="orgId" label="Org ID" return-object outlined dense/> -->
                            <v-autocomplete :items="orgId"
                              v-model="formData.OrgIdentification"
                              outlined
                              required
                              dense
                              item-text="divisionName"
                              label="Division"
                              return-object
                              item-value="name"></v-autocomplete>
                          </v-col>
                          <v-col v-if="getUserRole('HRCLU') | getUserRole('SCM')" cols="12" sm="6">
                            <v-text-field
                              dense
                              readonly
                              outlined
                              required
                              type="text"
                              label="On Behalf Of"
                              v-model="mPersonnel"
                              :append-icon="'mdi-magnify'"
                              @click:append="perSearch = true"
                              @click.prevent="perSearch = true" />
                          </v-col>

                          <v-col cols="12" sm="6">
                            <v-text-field dense
                              readonly
                              outlined
                              required
                              type="text"
                              label="Supervisor"
                              v-model="supervisorName"
                              :append-icon="'mdi-magnify'"
                              @click:append="supervisorSearch = true"
                              @click.prevent="supervisorSearch = true" />

                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field dense
                              readonly
                              outlined
                              required
                              type="text"
                              label="Alternate Supervisor"
                              v-model="altSupervisorName"
                              :append-icon="'mdi-magnify'"
                              @click:append="altSupervisorSearch = true"
                              @click.prevent="altSupervisorSearch = true" />

                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field dense
                              readonly
                              outlined
                              required
                              type="text"
                              label="Superintendent"
                              v-model="SuperintendentName"
                              :append-icon="'mdi-magnify'"
                              @click:append="superintendentSearch = true"
                              @click.prevent="superintendentSearch = true" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field dense
                              readonly
                              outlined
                              required
                              type="text"
                              label="Alternate Superintendent"
                              v-model="altSuperintendentName"
                              :append-icon="'mdi-magnify'"
                              @click:append="altSuperintendentSearch = true"
                              @click.prevent="altSuperintendentSearch = true" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field dense
                              readonly
                              outlined
                              required
                              label="GM"
                              type="text"
                              v-model="GMname"
                              :append-icon="'mdi-magnify'"
                              @click:append="gmSearch = true"
                              @click.prevent="gmSearch = true" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField v-model="receivingGM"
                              :disable="true"
                              :label="'Receiving GM'" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField v-model="formData.effectiveDate"
                              :type="'date'"
                              :label="'Effective Date'" />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="d-flex justify-end">
                            <div class="btn-group">
                              <Button :label="'Submit'"
                                :btnType="'Submit'"
                                @onClick="dialog = true"
                                :isLoading="isLoading"
                                class="mr-4" />

                              <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
                            </div>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- Personnel  data form section end  -->
                </v-expansion-panels>
              </div>
              <PersonnelSearch :dialog="personnelSearch" @selectPer="personnelSelected ($event)"  @close="personnelSearch = false" :type="'personnel'" :title="'Search for Personnel'"/>

              <PersonnelSearch :dialog="supervisorSearch" @selectPer="approver ($event,'SuperVisor')"  @close="supervisorSearch = false" :type="'employee'" :title="'Supervisor'"/>

              <PersonnelSearch :dialog="altSupervisorSearch" @selectPer="approver ($event,'AltSuperVisor')"  @close="altSupervisorSearch = false" :type="'employee'" :title="'Alternate Supervisor'"/>

               <PersonnelSearch :dialog="superintendentSearch" @selectPer="approver ($event,'Superintendent')"  @close="superintendentSearch = false" :type="'employee'" :title="'Superintendent'"/>

              <PersonnelSearch :dialog="altSuperintendentSearch" @selectPer="approver ($event,'AltSuperintendent')"  @close="altSuperintendentSearch = false" :type="'employee'" :title="'Alternate Superintendent'"/>

              <PersonnelSearch :dialog="gmSearch" @selectPer="approver ($event,'GM')"  @close="gmSearch = false" :type="'employee'" :title="'GM'"/>
              <PersonnelSearch :dialog="perSearch" @selectPer="approver ($event,'OnBehalfOf')"  @close="perSearch = false" :type="'employee'" :title="'On Behalf Of'"/>

              <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isSavingUser" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
            </v-container>
          </div>
        </div>
      </v-app>
    </main>
</template>
<script>
import moment from 'moment'
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import {
  departmentService,
  workAreaService,
  jobTitleService,
  locationService,
  contractEmployerService,
  nmaIntegrationService,
  employeeService,
  rotationService,
  employeeMoveAuditTrailService,
  unionMemberService
} from '@/services'

export default {
  components: {
    TextField,
    Button,
    ConfirmationDialog,
    PersonnelSearch
  },
  inject: ['eventBus'],
  provide () {
    return {
      eventBus: this.eventBus
    }
  },
  data () {
    return {
      personnelName: '',
      personnelSearch: false,
      formDisabled: false,
      count: 0,
      search: '',
      alertMessage: '',
      alert: false,
      alertType: '',
      dialog: false,
      isLoading: false,
      supervisorSearch: false,
      altSupervisorSearch: false,
      superintendentSearch: false,
      altSuperintendentSearch: false,
      gmSearch: false,
      isEditLoading: false,
      supervisorLoading: false,
      superitendantLoading: false,
      GMname: '',
      gmLoading: false,
      valid: true,
      employees: [],
      emp1: [],
      cai1: [],
      employeeList: [],
      formData: {
        id: '',
        Cai: '',
        FirstName: '',
        prNumber: '',
        MiddleName: '',
        LastName: '',
        effectiveDate: '',
        Email: '',
        AnnualSalaryAmount: '',
        Mobile: '',
        ContractEmployer: '',
        chargeCode: '',
        Location: '',
        Department: '',
        JobTitle: '',
        personnel: '',
        Rotation: '',
        WorkArea: '',
        approvers: [],
        active: false,
        deleted: false,
        action: 'Edit',
        status: 0,
        addedBy: 'TQVD',
        dateAdded: '2020-08-23T00:00:00',
        lastModifiedDate: null,
        modifiedBy: 'admin',
        UnionMemberId: '',
        orgId: '',
        OrgIdentification: '',
        UnionMembership: '',
        oldJobTitle: '',
        oldLocation: '',
        oldRotation: '',
        oldDepartment: '',
        oldContractEmployer: '',
        oldWorkArea: '',
        oldAnnualSalaryAmount: '',
        oldUnionMembership: '',
        oldOrgIdentification: '',
        oldChargeCode: ''
      },
      SuperintendentName: '',
      supervisorName: '',
      salaryAmount: 0,
      contractEmployer: [],
      supervisor: [],
      superitendant: [],
      personnel: [],
      GMS: [],
      isSavingUser: false,
      headers: [
        {
          text: 'name',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'email',
          value: 'emailAddress'
        }
      ],
      unionMembers: [],
      altSupervisorName: '',
      altSuperintendentName: '',
      organisationIds: [],
      departments: [],
      jobTitles: [],
      workAreas: [],
      locations: [],
      rotations: [],
      orgId: [],
      chargeCode: [],
      receivingGM: '',
      oldSupervisorName: '',
      oldSuperintendentName: '',
      oldAltSupervisorName: '',
      oldAltSuperintendentName: '',
      oldGMname: '',
      mPersonnel: '',
      mPersonnelEmail: '',
      loadingPersonnelData: false,
      perSearch: false
    }
  },
  computed: {
    currentUser () {
      console.log('Role', this.$store.getters.users)
      return this.$store.getters.users
    }
  },
  mounted () {
    this.getAllUnionMembers()
    this.getAllDepartments()
    this.getAllWorkArea()
    this.getAllContractEmployers()
    this.getAllChargeCodes()
    this.getAllOrgId()
    this.getAllJobs()
    this.getAllLocations()
    this.getAllRotation()
    this.getAllOrganisationIds()
    // this.getAllEmployee()
  },
  methods: {
    getUserRole (role) {
      if (this.currentUser.roles.includes(role)) {
        return true
      } else {
        return false
      }
    },
    personnelSelected (item) {
      this.personnelName = item.name
      this.getEmployeeById(item.id)
    },
    getAllEmployee () {
      employeeService.getAllEmployees().then(result => {
        const employees = result.data.items
        this.employeeList = employees
        employees.forEach(emp => {
          this.employees.push({
            employeeFullName: emp.firstName + ',' + emp.lastName,
            cai: emp.cai,
            id: emp.id
          })
        })
      }).catch(() => {
      })
    },
    getAllUnionMembers () {
      unionMemberService.getAllUnionMembers().then(result => {
        this.unionMembers = result.data
      })
    },
    getAllDepartments () {
      departmentService.getAllDepartments().then((result) => {
        this.departments = result.data.items
      })
    },
    getAllWorkArea () {
      workAreaService.getAllWorkArea().then((result) => {
        this.workAreas = result.data.items
      })
    },
    getAllContractEmployers () {
      contractEmployerService.getAllContractEmployers().then((result) => {
        this.contractEmployer = result.data.items
      })
    },
    getAllChargeCodes () {
      nmaIntegrationService
        .getAllChargeCodes()
        .then((result) => {
          this.chargeCode = result.data
        })
        .catch(() => { })
    },
    getAllOrgId () {
      nmaIntegrationService
        .getAllOrgId()
        .then((result) => {
          this.orgId = result.data
          this.count = this.count + 1
        })
        .catch((error) => {
          console.log('errorChargecode', error)
        })
    },
    getAllJobs () {
      jobTitleService
        .getAllJobTitles()
        .then((result) => {
          this.jobTitles = result.data.items
        })
        .catch(() => { })
    },
    getAllLocations () {
      locationService
        .getLocations()
        .then((result) => {
          this.locations = result.data.items
        })
        .catch(() => { })
    },
    getAllRotation () {
      rotationService.getAllRotation().then((result) => {
        this.rotations = result.data.items
      }).catch(() => { })
    },
    getAllOrganisationIds () {
      employeeService.getOrganisationIds().then(result => {
        // console.log('orgs', result)
        this.organisationIds = result.data
      })
    },
    getEmployeeById (id) {
      employeeService.getEmployeesById(id).then(result => {
        this.edit(result.data)
      }).catch(error => {
        this.showAlertMessage(error.message, 'error')
      })
    },
    getStorageItem (val) {
      const data = localStorage.getItem(val)
      return JSON.parse(data)
    },
    unionMemberChange (value) {
      if (value !== null) {
        const self = this
        jobTitleService.getJobTitleByUnion(value.id).then(result => {
          self.jobTitles = result.data
        })
      }
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    getSalaryAmount (item) {
      const jobtitle = this.jobTitles.filter(title => title.id === item)
      this.salaryAmount = this.formatMoney(jobtitle[0].salaryAmount)
    },
    openSupervisorDialog () {
      this.$refs.Supervisordialog.toggleModal()
    },
    openAltSupervisorDialog () {
      this.$refs.AltSupervisordialog.toggleModal()
    },
    formatMoney (amount, decimalCount = 2, decimal = '.', thousands = ',') {
      try {
        decimalCount = Math.abs(decimalCount)
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount

        const negativeSign = amount < 0 ? '-' : ''

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
        let j = (i.length > 3) ? i.length % 3 : 0

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
      } catch (e) {}
    },
    openSuperintendentDialog () {
      this.$refs.Superintendentdialog.toggleModal()
    },
    openAltSuperintendentDialog () {
      this.$refs.AltSuperintendentdialog.toggleModal()
    },
    openGMDialog () {
      this.$refs.GMdialog.toggleModal()
    },
    approver (item, title) {
      let approverTitle = title
      let alternate = false
      if (title === 'SuperVisor') {
        this.search = ''
        this.supervisor = []
        this.supervisorName = item.emailAddress
        approverTitle = 'SuperVisor'
      } else if (title === 'OnBehalfOf') {
        this.search = ''
        this.superitendant = []
        approverTitle = 'On Behalf Of'
        this.mPersonnel = item.name
        this.mPersonnelEmail = item.emailAddress
      } else if (title === 'Superintendent') {
        this.search = ''
        this.superitendant = []
        approverTitle = 'Superintendent'
        this.SuperintendentName = item.emailAddress
      } else if (title === 'GM') {
        this.search = ''
        this.GMS = []
        this.GMname = item.emailAddress
      } else if (title === 'AltSuperVisor') {
        this.search = ''
        this.supervisor = []
        this.altSupervisorName = item.emailAddress
        approverTitle = 'SuperVisor'
        alternate = true
      } else if (title === 'AltSuperintendent') {
        this.search = ''
        this.supervisor = []
        this.altSuperintendentName = item.emailAddress
        approverTitle = 'Superintendent'
        alternate = true
      }

      const newApprovers = []
      if (this.formData.approvers.length > 0) {
        this.formData.approvers.forEach(item => {
          if (!(item.approverTitle === approverTitle && item.isAlternate === alternate)) {
            newApprovers.push(item)
          }
        })
      }

      newApprovers.push({
        approvercai: item.id,
        approverEmail: item.emailAddress,
        approverTitle: approverTitle,
        isAlternate: alternate
      })
      this.formData.approvers = []
      this.formData.approvers = newApprovers
      console.log(newApprovers)
    },
    superVisorSearchInput () {
      if (this.search === '') {
        this.supervisor = []
        return
      }
      this.supervisorLoading = true
      employeeService.searchEmployees(this.search).then(result => {
        this.supervisor = result.data
      }).catch().finally(() => {
        this.supervisorLoading = false
      })
    },
    SuperintendentInput () {
      if (this.search === '') {
        this.superitendant = []
        return
      }
      this.superitendantLoading = true
      employeeService.searchEmployees(this.search).then(result => {
        this.superitendant = result.data
      }).catch(() => {
        this.showAlertMessage('Unable to load, Try again', 'error')
      }).finally(() => {
        this.superitendantLoading = false
      })
    },
    GMInput () {
      if (this.search === '') {
        this.GMS = []
        return
      }
      this.gmLoading = true
      employeeService.searchEmployees(this.search).then(result => {
        this.GMS = result.data
      }).catch(() => {
        this.showAlertMessage('Unable to load, Try again', 'error')
      }).finally(() => {
        this.gmLoading = false
      })
    },
    onSave () {
      this.isLoading = true
      const data = {
        EmployeeId: this.formData.id,
        chargeCode: this.formData.chargeCode.costCenter,
        LocationId: this.formData.Location.id,
        DepartmentId: this.formData.Department.id,
        JobTitleId: this.formData.JobTitle.id,
        RotationId: this.formData.Rotation.id,
        WorkAreaId: this.formData.WorkArea.id,
        AnnualSalaryAmount: this.formData.annualSalaryAmount,
        status: 0,
        UnionMemberId: this.formData.UnionMembership.id,
        orgId: this.formData.OrgIdentification.id,
        EffectiveDate: this.formData.effectiveDate,
        ApproversTemp: this.formData.approvers,
        ReceivingGMEmail: this.receivingGM,
        OnBehalfOfEmail: this.mPersonnelEmail
      }
      console.log('Data', data)
      employeeMoveAuditTrailService.createPersonnelMove(data).then(() => {
        this.showAlertMessage('Personnel Move successful', 'success')
        this.$router.go()
      }).catch(() => {
        this.showAlertMessage('Personnel Move failed', 'error')
      }).finally(() => {
        this.isLoading = false
        this.dialog = false
      })
    },
    closeSuperintendentDialog () {
      this.$refs.Superintendentdialog.closeModal()
    },
    closeGMDialog () {
      this.$refs.GMdialog.closeModal()
    },
    closeSupervisorDialog () {
      this.$refs.Supervisordialog.closeModal()
    },
    closeAltSuperintendentDialog () {
      this.$refs.AltSuperintendentdialog.closeModal()
    },
    closeAltSupervisorDialog () {
      this.$refs.AltSupervisordialog.closeModal()
    },
    closePersonnelSearchModal () {
      this.$refs.mPersonnelSearch.closeModal()
    },

    onReset () {
      this.$refs.employmentForm.reset()
      this.$refs.personnelForm.reset()
      this.SuperintendentName = ''
      this.supervisorName = ''
      this.altSupervisorName = ''
      this.altSuperintendentName = ''
      this.GMName = ''
      this.superitendant = []
      this.supervisor = []
      this.GMS = []
    },
    edit (item) {
      console.log('Edit Item', item)

      this.unionMemberChange(item.unionMembership)
      this.formData.id = item.id
      this.formData.Cai = item.cai
      this.formData.Location = item.location
      this.formData.Rotation = item.rotation
      this.formData.Department = item.department
      this.formData.ContractEmployer = item.contractEmployer
      this.formData.WorkArea = item.workArea
      this.formData.JobTitle = item.jobTitle
      this.formData.prNumber = item.prnumber
      this.formData.AnnualSalaryAmount = item.annualSalaryAmount
      this.formData.UnionMembership = item.unionMembership
      this.formData.OrgIdentification = this.orgId.filter(org => org.id === item.orgId)[0]
      this.formData.chargeCode = { 'costCenter': item.chargeCode, 'costCenterDesc': item.chargeCode }
      const superVisor = item.approvers.find(app => app.approverTitle === 'SuperVisor' && app.isAlternate === false)
      const superitendant = item.approvers.find(app => app.approverTitle === 'Superintendent' && app.isAlternate === false)
      const altSupervisor = item.approvers.find(app => app.approverTitle === 'SuperVisor' && app.isAlternate === true)
      const altSuperintendent = item.approvers.find(app => app.approverTitle === 'Superintendent' && app.isAlternate === true)
      const gm = item.approvers.find(app => app.approverTitle === 'GM' && app.isAlternate === false)
      this.supervisorName = superVisor ? superVisor.approverEmail : ''
      this.SuperintendentName = superitendant ? superitendant.approverEmail : ''
      this.altSupervisorName = altSupervisor ? altSupervisor.approverEmail : ''
      this.altSuperintendentName = altSuperintendent ? altSuperintendent.approverEmail : ''
      this.GMname = gm ? gm.approverEmail : ''
      this.formData.approvers = item.approvers
      this.populateOldForm(item)
    },
    populateOldForm (item) {
      console.log('Old form', item)

      // this.unionMemberChange(item.unionMembership)
      this.formData.id = item.id
      this.formData.oldLocation = item.location
      this.formData.oldRotation = item.rotation
      this.formData.oldDepartment = item.department
      this.formData.oldContractEmployer = item.contractEmployer
      this.formData.oldWorkArea = item.workArea
      this.formData.oldJobTitle = item.jobTitle
      this.formData.oldAnnualSalaryAmount = item.annualSalaryAmount
      this.formData.oldUnionMembership = item.unionMembership
      this.formData.oldOrgIdentification = this.orgId.filter(org => org.id === item.orgId)[0]
      this.formData.oldChargeCode = { 'costCenter': item.chargeCode, 'costCenterDesc': item.chargeCode }
      const superVisor = item.approvers.find(app => app.approverTitle === 'SuperVisor' && app.isAlternate === false)
      const superitendant = item.approvers.find(app => app.approverTitle === 'Superintendent' && app.isAlternate === false)
      const altSupervisor = item.approvers.find(app => app.approverTitle === 'SuperVisor' && app.isAlternate === true)
      const altSuperintendent = item.approvers.find(app => app.approverTitle === 'Superintendent' && app.isAlternate === true)
      const gm = item.approvers.find(app => app.approverTitle === 'GM' && app.isAlternate === false)
      this.oldSupervisorName = superVisor ? superVisor.approverEmail : ''
      this.oldSuperintendentName = superitendant ? superitendant.approverEmail : ''
      this.oldAltSupervisorName = altSupervisor ? altSupervisor.approverEmail : ''
      this.oldAltSuperintendentName = altSuperintendent ? altSuperintendent.approverEmail : ''
      this.oldGMname = gm ? gm.approverEmail : ''
    },
    DateOfBirthSelected () {
      const date = moment(this.formData.DateOfBirth).format('DD-MM-YYYY')
      let newDate = moment(date, 'DD-MM-YYYY').add(60, 'years')
      console.log('🚀 ~ file: editPersonnel.vue ~ line 733 ~ DateOfBirthSelected ~ newDate', newDate)
      this.formData.RetirementDate = newDate.format('MM-DD-YYYY')
    },
    departmentChange (item) {
      employeeMoveAuditTrailService.getReceivingGM(item.id).then(result => {
        this.receivingGM = result.data[0].approverEmail
      })
    }
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.btn-style{
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  margin-bottom: 12px !important;
}
.text-style{
  text-transform: capitalize;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
